import axios from '@/libs/axios'

export function reqGetTrainingRequests(payload) {
  return axios.get('api/admin/training-requests', { params: payload })
}
export function reqGetTrainingRequest(id) {
  return axios.get(`api/admin/training-requests/${id}`)
}
export function reqCreateTrainingRequest(payload) {
  return axios.post(`api/admin/training-requests`, payload)
}
export function reqUpdateTrainingRequest(id, payload) {
  return axios.post(`api/admin/training-requests/${id}?_method=PATCH`, payload)
}
export function reqDeleteTrainingRequest(id) {
  return axios.delete(`api/admin/training-requests/${id}`)
}